import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FormReducer from "../utils/FormReducer";
import { GlobalContext } from "../components/contextProvider/ContextProvider";
import RbA from "../components/rba/RbA";
import Header from "../components/header/Header";
import Toolbar from "../components/Toolbar";
import BreadCrumb from "../components/breadCrumb/BreadCrumb";
import StudentInfoBlock from "../components/studentInfoBlock/StudentInfoBlock";
import OpenEnrollmentDao from "../dao/OpenEnrollmentDao";
import StudentInfoDao from "../dao/StudentInfoDao";
import FeederBlock from "../components/feeders/FeederBlock";
import SelectedSchools from "../components/selectedSchools/SelectedSchools";
import SelectedSchoolsBlock from "../components/selectedSchools/SelectedSchoolsBlock";
import SaveSchoolChoices from "../components/saveBlock/SaveSchoolChoices";
import { formatDateAndTime, getEpoch, getTodayWithTime } from "../utils/DateFormatter";
import DcsdDialog from "../components/modals/DcsdDialog";
import ActionButton from "../components/formInputs/buttons/ActionButton";
import { EMPLOYEE_DASHBOARD, GUARDIAN_DASHBOARD } from "../utils/auth/config";
import WindowAndPermissionDialog from "../components/modals/WindowAndPermissionDialog";
import LoadingSvg from "../components/loadingSvg/LoadingSvg";
import GoldenRodBox from "../components/goldenRodBox/GoldenRodBox";
import BackOnly from "../components/saveBlock/BackOnly";
import SaveAcceptedOffer from "../components/saveBlock/SaveAcceptedOffer";

import "../styles/SchoolChoice.scss";

const SchoolChoice = () => {
    const { key } = useParams();
    const { dispatch, state } = useContext(GlobalContext);
    const { icStudentInfo, oeActiveDistrictWindow, oeStudentInfo, token, userDetails } = state || {};

    const initialFormState = { employeeNumber: "", employeeUsername: "", schoolChoiceDetailDtos: [] };

    const [formState, formDispatch] = useReducer(FormReducer, initialFormState);
    const [employeeDelete, setEmployeeDelete] = useState(false);
    const [feeders, setFeeders] = useState(null);
    const [feederFlag, setFeederFlag] = useState(true);
    const [guardianInfo, setGuardianInfo] = useState(null);
    const [isMutable, setIsMutable] = useState(false);
    const [loader, setLoader] = useState(false);
    const [nextParentOffer, setNextParentOffer] = useState(null);
    const [oeActionControls, setOeActionControls] = useState(null);
    const [oeGuardian, setOeGuardian] = useState(null);
    const [oeGuardianFlag, setOeGuardianFlag] = useState(true);
    const [oeStudentInfoFlag, setOeStudentInfoFlag] = useState(true);
    const [open, setOpen] = useState("false");
    const [outOfBounds, setOutOfBounds] = useState(false);
    const [parentOffer, setParentOffer] = useState(null);
    const [parentRequest, setParentRequest] = useState(null);
    const [reasons, setReasons] = useState(null);
    const [schoolFinalize, setSchoolFinalize] = useState(null);
    const [selectedSchoolTab, setSelectedSchoolTab] = useState(0);
    const [sorryMessage, setSorryMessage] = useState("");
    const [sorryTitle, setSorryTitle] = useState("");

    const allowedRolesArray = ["EXTERNAL_GUARDIAN", "GUARDIAN"];

    const emailGuardian = useCallback(() => {
        if (guardianInfo && key && oeActiveDistrictWindow && token && guardianInfo.email?.length > 0) {
            const options = {
                action: "oeGuardianEmail",
                key,
                schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                token
            };
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors } = response.data;
                    if (errors && errors.length > 0) {
                        let errorMessage = "Unable to email confirmation to guardian.";
                        errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    } else {
                        toast.success(
                            `Open Enrollment school choice(s) confirmation emailed to ${guardianInfo.email}.`,
                            { autoClose: false }
                        );
                    }
                }
            });
        } else {
            toast.info("Unable to email school choice(s) confirmation as no valid guardian email exists.", {
                autoClose: false
            });
        }
    }, [guardianInfo, key, oeActiveDistrictWindow, token]);

    const getBreadCrumbLabel = () => {
        let label = "Open Enrollment";
        if (nextParentOffer || parentOffer) {
            label = `${label} > Schools are Preparing Offers`;
        } else {
            label = `${label} > Student School Selection`;
        }

        return label;
    };

    const getEmployeeIdTitle = useCallback(() => {
        return employeeDelete ? "Delete DCSD Employee Priority Points" : "DCSD Employee Priority Points";
    }, [employeeDelete]);

    const getEnrollmentRound = (er) => {
        if (er === "ROUND1") {
            return "Round 1";
        } else if (er === "ROUND2") {
            return "Round 2";
        }

        return er;
    };

    const getDeleteActions = () => {
        return (
            <>
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-delete"
                    disable={loader}
                    label="Delete Open Enrollment"
                    onClick={() => {
                        handleDelete();
                    }}
                />
            </>
        );
    };

    const getEmployeeIdActions = useCallback(() => {
        return employeeDelete ? (
            <>
                {oeGuardian && (
                    <ActionButton
                        className="action-button-delete-100"
                        disable={loader}
                        label="Delete"
                        onClick={handleEmployeeIdDelete}
                    />
                )}
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        toast.dismiss();
                        setEmployeeDelete(false);
                        setOpen("false");
                    }}
                />
            </>
        ) : (
            <>
                {oeGuardian && (
                    <ActionButton
                        className="action-button-delete-100"
                        label="Delete"
                        onClick={() => {
                            setEmployeeDelete(true);
                        }}
                    />
                )}
                <ActionButton
                    className="action-button-cancel"
                    label="Cancel"
                    onClick={() => {
                        toast.dismiss();
                        setEmployeeDelete(false);
                        setOpen("false");
                    }}
                />
                <ActionButton
                    className="action-button-150"
                    disable={loader}
                    label="Save"
                    onClick={handleEmployeeIdSubmit}
                />
            </>
        );
        /* eslint-disable-next-line */
    }, [
        employeeDelete,
        formState,
        guardianInfo,
        handleEmployeeIdSubmit,
        handleEmployeeIdDelete,
        loader,
        oeGuardian,
        token,
        userDetails
    ]);

    const getParentMessage = useCallback(() => {
        if (parentOffer) {
            return (
                <p>
                    The Open Enrollment window for {getEnrollmentRound(oeActiveDistrictWindow.enrollmentRound)} is now
                    open to review offers from your schools of interest. Please note, you may accept only one school
                    offer by clicking the &ldquo;Accept&rdquo; button. Once you&apos;ve accepted one school&apos;s
                    invitation, all other offers (as applicable) will be declined automatically. You can also manually
                    decline any or all offers extended by schools during this window. Once you have completed your
                    review, please click the &ldquo;Submit&rdquo; button to save your selections. You must disposition
                    offers by <b>{formatDateAndTime(parentOffer.endDate)}</b>.
                </p>
            );
        }

        return (
            <p>
                The Open Enrollment request window for {getEnrollmentRound(oeActiveDistrictWindow.enrollmentRound)} is
                now closed as schools review all parent requests. Please return beginning{" "}
                <b>{formatDateAndTime(nextParentOffer.startDate)}</b> to review offers and plan to take any required
                actions by <b>{formatDateAndTime(nextParentOffer.endDate)}</b> when the parent offer window closes.
            </p>
        );
    }, [nextParentOffer, oeActiveDistrictWindow, parentOffer]);

    const getSchoolChoiceDto = () => {
        const { schoolChoiceDetailDtos } = structuredClone(formState);
        const studentDetailsDto = structuredClone(oeStudentInfo);
        const newSchoolChoiceDetailDtos = schoolChoiceDetailDtos.reduce((results, dto) => {
            const tmpDto = dto;
            tmpDto.completionDate = getTodayWithTime();
            delete tmpDto.schoolDto;
            const { kinderChoice, languageChoice } = dto;
            if (kinderChoice && kinderChoice.length > 0) {
                tmpDto.kinderChoice = kinderChoice.join(",");
            }
            if (languageChoice && languageChoice.length > 0) {
                tmpDto.languageChoice = languageChoice.join(",");
            }
            results.push(tmpDto);

            return results;
        }, []);
        studentDetailsDto.schoolChoiceDetailDtos = newSchoolChoiceDetailDtos;

        return studentDetailsDto;
    };

    const handleChangeEmployee = (e) => {
        const { name, value } = e.target;
        formDispatch({
            type: "text",
            field: name,
            payload: value.trim()
        });
    };

    const handleDelete = () => {
        const options = {
            action: "oeByStudentKeyDelete",
            key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors } = response.data;
                if (errors && errors?.length > 0) {
                    let errorMessage = "Unable to Delete Student Open Enrollment.";
                    errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                    setOpen("false");

                    return false;
                }
                toast.success(
                    "Open Enrollment application successfully deleted for student. Redirecting to Parent Dashboard. ",
                    {
                        autoClose: false
                    }
                );
                setOpen("false");
                setTimeout(() => {
                    sessionStorage.clear();
                    window.location.replace(GUARDIAN_DASHBOARD);
                }, 3000);

                return true;
            }
            setLoader(false);
        });
    };

    const validateForm = useCallback(() => {
        const { employeeNumber, employeeUsername } = formState;
        if (/\s/.test(employeeNumber) || /\s/.test(employeeUsername)) {
            toast.error("Username and Employee ID fields cannot contain spaces!", { autoClose: false });
            return false;
        }
        if (employeeNumber && employeeNumber.charAt(0) === "0") {
            toast.error("Employee ID cannot start with a '0'!", { autoClose: false });
            return false;
        }
        if (!/^\d+$/.test(employeeNumber)) {
            toast.error("Employee ID must be a number!", { autoClose: false });
            return false;
        }

        return true;
    }, [formState]);

    const handleEmployeeIdDelete = useCallback(() => {
        toast.dismiss();
        const options = {
            action: "oeGuardianDelete",
            key: oeGuardian.key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors } = response.data;
                if (errors && errors.length > 0) {
                    let errorMessage = "There was a problem linking your employee data.";
                    errorMessage = `${errorMessage}:\n${errors.join("\n")}`;

                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                } else {
                    setOeGuardian(null);
                    const tmpFormState = structuredClone(formState);
                    tmpFormState.employeeNumber = "";
                    tmpFormState.employeeUsername = "";
                    formDispatch({
                        type: "reset",
                        payload: { ...tmpFormState }
                    });
                    toast.success(
                        "The DCSD Employee Username and DCSD Employee ID associated with this Open Enrollment request has been successfully removed."
                    );
                }
                setEmployeeDelete(false);
                window.location.reload();
            }
            setLoader(false);
        });
    }, [formDispatch, formState, oeGuardian, token]);

    const handleEmployeeIdSubmit = useCallback(() => {
        const employeeIdForm = document.getElementById("employee-id-form");
        const checkStatus = employeeIdForm.checkValidity();
        employeeIdForm.reportValidity();
        if (checkStatus && validateForm()) {
            toast.dismiss();
            setLoader(true);
            const action = oeGuardian ? "oeGuardianUpdate" : "oeGuardianCreate";
            const data = {
                employeeNumber: parseInt(formState.employeeNumber, 10),
                employeeUsername: formState.employeeUsername,
                key: oeGuardian ? oeGuardian.key : null,
                parentGuid: parseInt(userDetails.uid, 10)
            };
            const successVerb = oeGuardian ? "updated" : "added";
            const options = {
                action,
                data,
                key: oeGuardian ? oeGuardian.key : null,
                token
            };
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        setOeGuardian(payload);
                        toast.success(
                            `The DCSD Employee Username and DCSD Employee ID associated with this Open Enrollment request has been successfully ${successVerb}`
                        );
                    } else if (errors) {
                        let errorMessage = "There was a problem linking your employee data.";
                        if (errors && errors.length > 0) {
                            errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        }
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    }
                    window.location.reload();
                }
                setLoader(false);
            });
        }
    }, [formState, oeGuardian, token, userDetails, validateForm]);

    const handleSubmit = () => {
        const data = getSchoolChoiceDto();
        const options = {
            action: "oeByStudentKeyUpdate",
            data,
            key: oeStudentInfo.studentDto.key,
            token
        };
        setLoader(true);
        OpenEnrollmentDao(options).then((response) => {
            if (response) {
                const { errors, payload } = response.data;
                if (payload) {
                    // don't send an email if a parent is Accepting or Denying a school offer
                    if (parentOffer) {
                        toast.success(
                            "School choice offer acceptance and/or declination(s) successfully saved. Redirecting to Parent Dashboard.",
                            {
                                autoClose: 3000
                            }
                        );
                    } else {
                        // Parent Request
                        toast.success("School choices successfully saved. Redirecting to Parent Dashboard.", {
                            autoClose: false
                        });
                        emailGuardian();
                    }
                    setTimeout(() => {
                        sessionStorage.clear();
                        window.location.replace(GUARDIAN_DASHBOARD);
                    }, 4000);
                } else if (errors) {
                    let errorMessage = "There was a problem updating you School Choices: ";
                    if (errors && errors.length > 0) {
                        errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                    }
                    toast.error(`${errorMessage}`, {
                        autoClose: false,
                        closeOnClick: true,
                        style: { width: "100%", whiteSpace: "break-spaces" }
                    });
                }
            }
            setLoader(false);
        });
    };

    const updateSchoolChoiceDetailDtos = useCallback(
        (feedersObj) => {
            const feedersKeys = Object.keys(feedersObj);
            const tmpFormState = structuredClone(formState);
            const { schoolChoiceDetailDtos } = tmpFormState;

            if (feedersObj && schoolChoiceDetailDtos && schoolChoiceDetailDtos.length > 0) {
                const newDtos = schoolChoiceDetailDtos.reduce((results, dto) => {
                    const tmpDto = structuredClone(dto);
                    const { kinderChoice, languageChoice } = tmpDto;

                    const schoolMatch = feedersKeys.reduce((results2, feeder) => {
                        let match = [];
                        match = feedersObj[feeder].filter(
                            (obj) => parseInt(obj.key, 10) === parseInt(dto.participatingSchoolId, 10)
                        );
                        if (match && match.length > 0) {
                            results2.push(match[0]);
                        }
                        return results2;
                    }, []);
                    tmpDto.kinderChoice =
                        kinderChoice && kinderChoice.length > 0 ? kinderChoice.split(",") : kinderChoice;
                    tmpDto.languageChoice =
                        languageChoice && languageChoice.length > 0 ? languageChoice.split(",") : languageChoice;
                    tmpDto.schoolDto = schoolMatch[0];
                    results.push(tmpDto);

                    return results;
                }, []);

                tmpFormState.schoolChoiceDetailDtos = newDtos;
                formDispatch({
                    type: "reset",
                    payload: { ...tmpFormState }
                });
            }
        },
        [formState]
    );

    const showEmployeeButton = () => {
        return oeActiveDistrictWindow && oeActiveDistrictWindow.enrollmentRound === "ROUND1" && parentRequest;
    };

    /**
     * Get reasons
     */
    useEffect(() => {
        if (token && !reasons) {
            const options = {
                action: "oeGetReasons",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload && payload.length > 0) {
                        payload.sort((a, b) => {
                            return a.questionText > b.questionText ? 1 : -1;
                        });
                        const other = payload.filter((obj) => obj.questionText.toLowerCase() === "other");
                        if (other && other.length > 0) {
                            let match = [];
                            match = payload.filter((obj) => obj.questionText.toLowerCase() !== "other");
                            match.push(other[0]);
                            setReasons(match);
                        }
                    } else if (errors) {
                        let errorMessage = "There was a problem retrieving Reasons for Applying: ";
                        if (errors && errors.length > 0) {
                            errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        }
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    }
                }
                setLoader(false);
            });
        }
    }, [reasons, token]);

    useEffect(() => {
        if (token && !oeActiveDistrictWindow) {
            const options = {
                action: "oeActiveDistrictWindowRead",
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        dispatch({
                            type: "OeActiveDistrictWindow",
                            oeActiveDistrictWindow: payload
                        });
                    } else if (payload === null) {
                        setOutOfBounds(true);
                        setSorryTitle("Open Enrollment Window Closed");
                        setSorryMessage(
                            "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                        );
                        setOpen("out-of-bounds");
                    } else if (errors) {
                        let errorMessage = "There was a problem retrieving Open Enrollment Active Window: ";
                        if (errors && errors.length > 0) {
                            errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        }
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    }
                }
                setLoader(false);
            });
        }
    }, [dispatch, oeActiveDistrictWindow, token]);

    /**
     * Get IC guardian information (infinite campus)
     */
    useEffect(() => {
        if (token && userDetails && !guardianInfo) {
            const { roleDtos } = userDetails;
            const match = roleDtos.filter((obj) => obj.role === "GUARDIAN");
            if (match && match.length > 0) {
                const options = {
                    action: "guardianInfoRead",
                    username: userDetails?.username,
                    token
                };
                setLoader(true);
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;
                        if (payload) {
                            setGuardianInfo(payload);
                        } else if (errors) {
                            setGuardianInfo(null);
                            let errorMessage =
                                "There was a problem loading your data. Please reload the page and try again. ";
                            if (errors && errors.length > 0) {
                                errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                            }
                            toast.error(`${errorMessage}`, {
                                autoClose: false,
                                closeOnClick: true,
                                style: { width: "100%", whiteSpace: "break-spaces" }
                            });
                        }
                    }
                    setLoader(false);
                });
            } else {
                setGuardianInfo(userDetails);
            }
        }
    }, [guardianInfo, token, userDetails]);

    /**
     * Get OE Student Data
     */
    useEffect(() => {
        if (key && token && oeActiveDistrictWindow && oeStudentInfoFlag && !oeStudentInfo) {
            setOeStudentInfoFlag(false);
            const options = {
                action: "oeByStudentKeyRead",
                key: key,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        const tmpFormState = structuredClone(formState);
                        tmpFormState.schoolChoiceDetailDtos = payload.schoolChoiceDetailDtos;
                        dispatch({
                            type: "OeStudentInfo",
                            oeStudentInfo: payload
                        });
                        formDispatch({
                            type: "reset",
                            payload: { ...tmpFormState }
                        });
                    } else if (errors) {
                        let errorMessage = "There was a problem retrieving Student details";
                        if (errors && errors.length > 0) {
                            errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        }
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    }
                } else {
                    setOutOfBounds(true);
                    setSorryTitle("Access Denied");
                    setSorryMessage(
                        "Sorry, but you do not have permission to view this student. Please return to Parent Dashboard."
                    );
                    setOpen("out-of-bounds");
                }
                setLoader(false);
            });
        }
    }, [dispatch, formDispatch, formState, oeActiveDistrictWindow, oeStudentInfo, oeStudentInfoFlag, key, token]);

    /**
     * Check if the Open Enrollment window is open or closed
     * If outOfBounds === false, then the window is open
     *  */
    useEffect(() => {
        if (oeActiveDistrictWindow && !outOfBounds) {
            const { endDate, startDate } = oeActiveDistrictWindow;
            const today = getTodayWithTime();
            if (getEpoch(endDate) > getEpoch(today) && getEpoch(startDate) < getEpoch(today)) {
                setOutOfBounds(false);
            }
        }
    }, [oeActiveDistrictWindow, outOfBounds]);

    useEffect(() => {
        if (token && userDetails && oeGuardianFlag && !oeStudentInfoFlag && !oeGuardian) {
            setOeGuardianFlag(false);
            const options = {
                action: "oeGuardianByGuidRead",
                guid: userDetails.uid,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload) {
                        setOeGuardian(payload);
                    }
                }
                setLoader(false);
            });
        }
        /* eslint-disable-next-line */
    }, [formDispatch, formState, oeGuardian, oeGuardianFlag, oeStudentInfoFlag, token, userDetails]);

    /**
     * Get OE Student Data
     */
    useEffect(() => {
        if (oeStudentInfo && token && !icStudentInfo) {
            const { studentDto } = oeStudentInfo;
            if (studentDto?.studentNumber) {
                const options = {
                    action: "studentInfoByNumberGet",
                    studentNumber: studentDto.studentNumber,
                    token
                };
                setLoader(true);
                StudentInfoDao(options).then((response) => {
                    if (response) {
                        const { errors, payload } = response.data;
                        if (payload) {
                            dispatch({
                                type: "IcStudentInfo",
                                icStudentInfo: payload
                            });
                        } else if (errors && errors.length > 0) {
                            let errorMessage = "There was a problem retrieving Student details from IC.";
                            if (errors && errors.length > 0) {
                                errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                            }
                            toast.error(`${errorMessage}`, {
                                autoClose: false,
                                closeOnClick: true,
                                style: { width: "100%", whiteSpace: "break-spaces" }
                            });
                        }
                    }
                });
                setLoader(false);
            }
        }
    }, [dispatch, icStudentInfo, oeStudentInfo, token]);

    useEffect(() => {
        if (feederFlag && oeActiveDistrictWindow && oeStudentInfo && token && !feeders) {
            setFeederFlag(false);
            const { studentDto } = oeStudentInfo;
            const options = {
                action: "oeSchoolsByStudent",
                key: studentDto.key,
                params: {
                    enrollmentRound: oeActiveDistrictWindow.enrollmentRound
                },
                schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { errors, payload } = response.data;
                    if (payload) {
                        setFeeders(payload);
                        updateSchoolChoiceDetailDtos(payload);
                    } else if (errors) {
                        let errorMessage = "There was a problem retrieving Region Schools: ";
                        if (errors && errors.length > 0) {
                            errorMessage = `${errorMessage}:\n${errors.join("\n")}`;
                        }
                        toast.error(`${errorMessage}`, {
                            autoClose: false,
                            closeOnClick: true,
                            style: { width: "100%", whiteSpace: "break-spaces" }
                        });
                    }
                }
                setLoader(false);
            });
        }
    }, [feeders, feederFlag, oeActiveDistrictWindow, oeStudentInfo, token, updateSchoolChoiceDetailDtos]);

    /**
     * Get the Action Controls for Open Enrollment
     * Should I check the start and end dates?
     */
    useEffect(() => {
        if (oeActiveDistrictWindow && token && !oeActionControls) {
            const options = {
                action: "oeActionControls",
                params: {
                    differentiator: oeActiveDistrictWindow.key
                },
                token
            };
            setLoader(true);
            OpenEnrollmentDao(options).then((response) => {
                if (response) {
                    const { payload } = response.data;
                    if (payload && payload.length > 0) {
                        const match = payload.filter((obj) =>
                            ["SCHOOL_OFFER_PENDING", "ADMIN_OFFER_CONFIRM"].includes(obj.action)
                        );
                        const match2 = payload.filter((obj) => ["PARENT_OFFER"].includes(obj.action));
                        const match3 = payload.filter((obj) => ["SCHOOL_FINALIZE"].includes(obj.action));
                        const match4 = payload.filter((obj) =>
                            [
                                "PARENT_REQUEST",
                                "PARENT_OFFER",
                                "ADMIN_OFFER_CONFIRM",
                                "SCHOOL_OFFER_PENDING",
                                "SCHOOL_FINALIZE"
                            ].includes(obj.action)
                        );
                        const match5 = payload.filter((obj) => ["PARENT_REQUEST"].includes(obj.action));
                        if (match5 && match5.length > 0) {
                            setParentRequest(match5[0]);
                        } else if (match && match.length > 0 && oeActiveDistrictWindow.enrollmentRound === "ROUND1") {
                            // parent request closed, we have to get the start date for when they can do some action
                            const options2 = {
                                action: "oeActionControlsByAction",
                                params: {
                                    action: "PARENT_OFFER",
                                    differentiator: match[0].differentiator,
                                    schoolYearKey: oeActiveDistrictWindow.schoolYearKey,
                                    who: "default",
                                    whoType: "DEFAULT"
                                },
                                token
                            };
                            OpenEnrollmentDao(options2).then((response2) => {
                                if (response2) {
                                    const payload2 = response2.data.payload;
                                    if (payload2 && payload2.length > 0) {
                                        setNextParentOffer(payload2[0]);
                                    }
                                }
                            });
                        } else if (match2 && match2.length > 0) {
                            setParentOffer(match2[0]);
                        } else if (match3 && match3.length > 0) {
                            setSchoolFinalize(match3[0]);
                        } else if (match4 && match4.length === 0) {
                            setOutOfBounds(true);
                            setSorryTitle("Open Enrollment Window Closed");
                            setSorryMessage(
                                "The Open Enrollment window is now closed. Please return during the district's parent-access windows. Thank you."
                            );
                            setOpen("out-of-bounds");
                        }
                        setOeActionControls(payload);
                    }
                }
                setLoader(false);
            });
        }
    }, [oeActionControls, oeActiveDistrictWindow, token]);

    useEffect(() => {
        if (oeGuardian) {
            const { employeeNumber, employeeUsername } = oeGuardian;
            const tmpFormState = formState;
            if (
                employeeNumber.length > 0 &&
                employeeUsername?.length > 0 &&
                (tmpFormState.employeeNumber?.length < 1 || tmpFormState?.employeeUsername?.length < 1)
            ) {
                tmpFormState.employeeNumber = employeeNumber;
                tmpFormState.employeeUsername = employeeUsername;
            }
            formDispatch({
                type: "reset",
                payload: { ...tmpFormState }
            });
        }
        /* eslint-disable-next-line */
    }, [feeders, formDispatch, oeGuardian]);

    useEffect(() => {
        if (!parentOffer) {
            setIsMutable(false);
        } else {
            setIsMutable(true);
        }
    }, [isMutable, parentOffer]);

    /**
     * if ROUND2 and PARENT_REQUEST, sort school choices to display ENROLLED, and then CHANGED_ENROLLMENT and then by
     * key
     */
    useEffect(() => {
        if (oeActionControls && oeActiveDistrictWindow && oeStudentInfo) {
            if (oeActiveDistrictWindow.enrollmentRound === "ROUND2") {
                const match = oeActionControls.filter((obj) => ["PARENT_REQUEST"].includes(obj.action));
                if (match && match.length > 0) {
                    const tmpFormState = formState;
                    const { schoolChoiceDetailDtos } = tmpFormState;
                    if (schoolChoiceDetailDtos && schoolChoiceDetailDtos.length > 0) {
                        const enrolled = schoolChoiceDetailDtos.filter((obj) => obj.status === "ENROLLED");
                        const unenrolled = schoolChoiceDetailDtos.filter((obj) => obj.status === "CHANGED_ENROLLMENT");
                        const theRest = schoolChoiceDetailDtos.filter(
                            (obj) => obj.status !== "ENROLLED" && obj.status !== "CHANGED_ENROLLMENT"
                        );
                        const tmpSort = [];
                        if (enrolled && enrolled.length > 0) {
                            tmpSort.push(enrolled[0]);
                        }
                        if (unenrolled && unenrolled.length > 0) {
                            tmpSort.push(unenrolled[0]);
                        }
                        if (theRest && theRest.length > 0) {
                            theRest.sort((a, b) => {
                                return parseInt(a.key, 10) > parseInt(b.key, 10) ? 1 : -1;
                            });
                        }
                        const combine = tmpSort.concat(theRest) || [];
                        tmpFormState.schoolChoiceDetailDtos = combine;
                        formDispatch({
                            type: "reset",
                            payload: { ...tmpFormState }
                        });
                    }
                }
            }
        }
        /* eslint-disable-next-line */
    }, [oeActionControls, oeActiveDistrictWindow, oeStudentInfo]);

    return (
        <RbA allowedRoles={allowedRolesArray} redirect="/notFound">
            <ToastContainer style={{ width: "50%" }} />
            <Header />
            <Toolbar />
            <BreadCrumb label={getBreadCrumbLabel()} />
            <div className="school-choice-page">
                <div className="employee-id-container">
                    <div className="gutter-90">{oeStudentInfo && <StudentInfoBlock studentInfo={oeStudentInfo} />}</div>
                    {showEmployeeButton() && (
                        <ActionButton
                            className="action-button-reg employee-id-button"
                            label="DCSD Employee Enter ID"
                            onClick={() => {
                                setOpen("employee-id");
                            }}
                        />
                    )}
                </div>
                {feeders && reasons && parentRequest && (
                    <>
                        <FeederBlock
                            currentFeeder={oeStudentInfo?.feederRegion}
                            feeders={feeders}
                            formDispatch={formDispatch}
                            formState={formState}
                            oeStudentInfo={oeStudentInfo}
                            setSelectedSchoolTab={setSelectedSchoolTab}
                        />
                        {formState.schoolChoiceDetailDtos.length > 0 && oeStudentInfo && (
                            <>
                                <hr className="hr-page" />
                                <SelectedSchoolsBlock
                                    feeders={feeders}
                                    formDispatch={formDispatch}
                                    formState={formState}
                                    oeStudentInfo={oeStudentInfo}
                                    reasons={reasons}
                                    selectedSchoolTab={selectedSchoolTab}
                                    setSelectedSchoolTab={setSelectedSchoolTab}
                                />
                            </>
                        )}
                        <SaveSchoolChoices
                            formState={formState}
                            handleSubmit={handleSubmit}
                            loader={loader}
                            open={open}
                            reasons={reasons}
                            setOpen={setOpen}
                            studentDto={oeStudentInfo.studentDto}
                        />
                        {oeStudentInfo && (
                            <>
                                <DcsdDialog
                                    actions={getDeleteActions()}
                                    ariaLabel="Delete Open Enrollment Confirmation Dialog"
                                    hasCloseX
                                    id="delete"
                                    onHide={() => {
                                        setOpen("false");
                                    }}
                                    open={open}
                                    title="Delete Open Enrollment Confirmation"
                                >
                                    <p>
                                        Please confirm that you would like to delete the Open Enrollment application for
                                        Student{" "}
                                        <span>
                                            {oeStudentInfo?.studentDto.firstName} {oeStudentInfo?.studentDto.lastName}
                                        </span>
                                        . This action will remove any associated Open Enrollment school choice data for
                                        the student.{" "}
                                    </p>
                                    {loader && (
                                        <div className="dialog-loader">
                                            Processing... Please Wait <LoadingSvg />
                                        </div>
                                    )}
                                </DcsdDialog>
                                <DcsdDialog
                                    actions={getEmployeeIdActions()}
                                    ariaLabel="Enter Employee ID Dialog"
                                    hasCloseX
                                    id="employee-id"
                                    onHide={() => {
                                        setOpen("false");
                                    }}
                                    open={open}
                                    title={getEmployeeIdTitle()}
                                >
                                    {employeeDelete ? (
                                        <div>
                                            <p>
                                                Please confirm you would like to delete the DCSD Employee Username{" "}
                                                <u>{formState.employeeUsername}</u> and DCSD Employee ID{" "}
                                                <u>{formState.employeeNumber}</u> from this Open Enrollment Request.
                                                This action will remove the corresponding priority points that may have
                                                been associated to your Open Enrollment school choices.
                                            </p>
                                            {loader && (
                                                <div className="dialog-loader">
                                                    Processing... Please Wait <LoadingSvg />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                            <div>
                                                <u>
                                                    Please enter your DCSD <strong>Employee Username</strong> and{" "}
                                                    <strong>Employee ID</strong>
                                                </u>{" "}
                                                to receive priority points. Your DCSD Employee ID should be entered{" "}
                                                <strong>WITHOUT</strong> leading zeros so the system can accurately
                                                validate. In order for the system to automatically assign priority
                                                points for the Open Enrollment request, the DCSD employee must have
                                                accessed and logged in to the{" "}
                                                <a href={EMPLOYEE_DASHBOARD} rel="noreferrer" target="_blank">
                                                    Employee Dashboard
                                                </a>{" "}
                                                at least one time.
                                            </div>
                                            <div>
                                                <form className="employee-id-form" id="employee-id-form">
                                                    <label>
                                                        Employee Username *
                                                        <input
                                                            id="employee-username"
                                                            name="employeeUsername"
                                                            onChange={handleChangeEmployee}
                                                            required
                                                            type="text"
                                                            value={formState.employeeUsername}
                                                        />
                                                        <p className="input-subtext">
                                                            Please enter the DCSD employee username associated with
                                                            Workday / DCSD Single-Sign-On
                                                        </p>
                                                    </label>
                                                    <label>
                                                        Employee ID *
                                                        <input
                                                            id="employee-id"
                                                            name="employeeNumber"
                                                            onChange={handleChangeEmployee}
                                                            required
                                                            type="text"
                                                            value={formState.employeeNumber}
                                                        />
                                                        <p className="input-subtext">
                                                            Please enter the employee ID associated with the username
                                                            you entered above
                                                        </p>
                                                    </label>
                                                </form>
                                            </div>
                                            {loader && (
                                                <div className="dialog-loader">
                                                    Processing... Please Wait <LoadingSvg />
                                                </div>
                                            )}
                                        </>
                                    )}
                                </DcsdDialog>
                            </>
                        )}
                    </>
                )}
                {feeders && formState && (nextParentOffer || parentOffer || schoolFinalize) && oeStudentInfo && (
                    <>
                        {!schoolFinalize && (
                            <div className="gutter-90">
                                <GoldenRodBox
                                    includeHr
                                    label="Important Parent Information"
                                    message={getParentMessage()}
                                    school={false}
                                />
                            </div>
                        )}
                        <SelectedSchools
                            formDispatch={formDispatch}
                            formState={formState}
                            isMutable={isMutable}
                            oeStudentInfo={oeStudentInfo}
                            parentOffer={parentOffer}
                            schoolFinalize={schoolFinalize}
                        />
                        {parentOffer ? (
                            <SaveAcceptedOffer
                                formDispatch={formDispatch}
                                formState={formState}
                                handleSubmit={handleSubmit}
                                isMutable={isMutable}
                                loader={loader}
                            />
                        ) : (
                            <BackOnly />
                        )}
                    </>
                )}
                {loader && <LoadingSvg />}
                <WindowAndPermissionDialog
                    id="out-of-bounds"
                    open={open}
                    setOpen={setOpen}
                    sorryTitle={sorryTitle}
                    sorryMessage={sorryMessage}
                />
            </div>
        </RbA>
    );
};

export default SchoolChoice;
